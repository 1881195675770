<template>
  <nav class="menu">
    <template v-if="!isEmpty">
      <ul class="menu-nav opened">
        <li
          v-for="(route, title) in navLinks"
          :key="route.name"
          class="menu-item menu-item_icon"
          :class="{ 'menu-item-active': getActiveStatus(route) }"
          @click="routeTo(route)"
          @mouseover="setHovered(title, true)"
          @mouseleave="setHovered(title, false)"
        >
          <svg-icon
            class="svg-icon-middle"
            :name="route.name"
            :hover-color="'#E24095'"
            :base-color="'#fff'"
            :hovered="getHoveredOrActiveStatus(route, title)"
          ></svg-icon>
          <div>
            <router-link
              :to="route"
              class="menu-item__link"
              style="
                text-decoration: none;
                font-size: 12px;
                color: white;
                line-height: 16px;
              "
              >{{ title }}</router-link
            >
          </div>
        </li>
      </ul>
    </template>
  </nav>
</template>

<script>
import { store } from "@/store";
import nav from "@/nav.json";
import SvgIcon from "@/components/SvgIcon.vue";
import "@/styles/components/menu.less";

export default {
  name: "NavBar",
  components: { SvgIcon },
  data: () => ({
    isShow: false,
    hoverAlias: "",
    localeRoute: null,
    navHovered: [],
  }),
  computed: {
    navLinks() {
      if (store.user) return store.user.isAdmin ? nav.admin : nav.doctor;
      else return [];
    },
    isEmpty() {
      return Object.keys(this.navLinks).length === 0;
    },
  },
  watch: {
    $route() {
      //localeRoute =
      this.isShow = false;
    },
  },
  methods: {
    routeTo(route) {
      this.$router.push(`/${route.name}`);
    },
    setHovered(key, value) {
      this.navHovered[key] = value;
    },
    getHoveredOrActiveStatus(route, title) {
      return this.navHovered[title] || this.getActiveStatus(route);
    },
    getActiveStatus(route) {
      return this.$route.name === route.name;
    },
  },
};
</script>
