import axios from 'axios'

//const token = localStorage.getItem('jwt')
let url = 'http://localhost:8080/api/v1'

if (window.location.hostname.indexOf('localhost') === -1) {
    url = window.location.protocol + '//' + window.location.host + "//api//v1"
    console.log (`New BASEURL ${url}`)
}

const http = axios.create({
    baseURL: url
})

//if (token) {
//    http.defaults.headers.common['Authorization'] = token
//}

export default http
