import AuthService from "@/services/auth.service";
import SettingsService from "@/services/settings.service"
import { reactive } from 'vue'


export const store = reactive ({
  count: 0,
  loggedIn : false,
  user : null,
  settings : null,
  getSettings() {
      return SettingsService.getSettings();
  },
  setSettings(settings) {
     this.settings = settings
  },

  login(userData) {
    return AuthService.login(userData).then(
        user => {
            this.loggedIn = true;
            this.user = user;
            return Promise.resolve(user);
        },
        error => {
          return Promise.reject(error);
        }
    );
  },
  logout() {
    AuthService.logout();
    this.loggedIn = false;
    this.user = null;
  },
    setUser(){
      this.user = { isAdmin : true }
    }

})
