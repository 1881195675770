<template>
  <header class="header">
    <div class="">
      <div class="d-flex align-items-center justify-content-between px-3">
        <div>
          <div class="d-flex align-items-center">
            <router-link to="/">
              <svg-icon
              style="display: block; height: 44px; width: 147px;"
              :name="'logo-ear-full'"
              ></svg-icon>
            </router-link>
          </div>
        </div>

        <div style="font-size:14px">
          <span>{{ roleStr }}: </span>
          <a class="" href @click.prevent="logOut"> {{ userName }} </a>
          <span class="dividingLine"></span>
          <a class="" href @click.prevent="logOut">Выйти</a>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { store } from "@/store";
import "@/styles/components/header.less";

import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "HeaderBody",
  components: { SvgIcon },
  data() {
    return {
      store,
    };
  },
  computed: {
    roleStr() {
      if (!store.user) return "";
      return store.user.isAdmin ? "Администратор" : "Врач"
    },
    userName() {
      if (!store.user) return "";
      return store.user.userName
    }
  },
  methods: {
    logOut() {
      store.logout();
      this.$router.push("/login");
    },
  },
};
</script>
