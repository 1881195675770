<template>
  <div class="cover-empty">
    <router-view></router-view>
    <footer-body
      :is-white-theme="true"
      style="position: fixed; bottom: 0; width: 100%"
    ></footer-body>
  </div>
</template>

<script>
import FooterBody from "@/views/FooterBody.vue";

require("@/styles/pages/empty-layout.less");

export default {
  name: "LayoutEmpty",
  components: {
    FooterBody,
  },
};
</script>
