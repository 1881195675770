<template>
  <div class="footer">
    <div class="container">

      <div class="d-flex align-items-center justify-content-between mb-2">
        <div class="d-flex align-items-center justify-content-start" style="position:relative">
          <svg-icon
            class="svg-icon-horizontal"
            :name="isWhiteTheme == true ? 'logo-ear-white-full' : 'logo-ear-full'"
            style="margin-right: 8px;"
          ></svg-icon>
          <span
            class="simple-text ms-4"
            :class="isWhiteTheme ? 'simple-text-white' : 'simple-text-grey'"
            style="font-size: 14px;"
          >© Neurosoft, 2022-{{new Date().getFullYear()}}</span>
        </div>
        
        <div>
          <a
            :class="{ white: isWhiteTheme }"
            href="https://neurosoft.com"
            target="_blank"
            >Наш веб-сайт</a
          >
          <span class="dividingLine" :class="{ 'dividingLine-white': isWhiteTheme }"></span>
          <a :class="{ white: isWhiteTheme }" href="">Контакты</a>
          <span class="dividingLine" :class="{ 'dividingLine-white': isWhiteTheme }"></span>
          <a :class="{ white: isWhiteTheme }" href="">Обратная связь</a>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import SvgIcon from "@/components/SvgIcon.vue";

export default {
  name: "FooterBody",
  components: { SvgIcon },
  props: {
    isWhiteTheme: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
};
</script>
