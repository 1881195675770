<template>
  <svg
    :class="className"
    xmlns="http://www.w3.org/2000/svg"
    @click.prevent="$emit('click:icon', $event)"
    @mouseenter="updateHoverState(true)"
    @mouseleave="updateHoverState(false)"
  >
    <title v-if="title">{{ title }}</title>
    <use
      :style="styleObject"
      :xlink:href="iconPath"
      xmlns:xlink="http://www.w3.org/1999/xlink"
    />
  </svg>
</template>
  
  <script>
import("@/styles/elements/svg-icon.less");
import hexToFilterString from "@/modules/colors";
export default {
  name: "SvgIcon",
  props: {
    name: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: null,
    },
    // цвет без наведения
    baseColor: {
      type: String,
      required: false,
      default: null,
    },
    // цвет при наведении
    hoverColor: {
      type: String,
      required: false,
      default: null,
    },
    // статус наведения из вне
    hovered: {
      type: Boolean,
      required: false,
      default: null,
    },
  },
  data: () => {
    return {
      hoverState: false,
    };
  },
  watch: {
    hovered: {
      handler() {
        this.hoverState = this.hovered;
      },
    },
  },
  computed: {
    iconPath() {
      let icon = require(`@/assets/icon/${this.name}.svg`);
      if (Object.prototype.hasOwnProperty.call(icon, "default")) {
        icon = icon.default;
      }
      return icon.url;
    },
    className() {
      return "svg-icon svg-icon--" + this.name;
    },
    baseFilterValue() {
      return this.baseColor != null
        ? hexToFilterString(this.baseColor).filter
        : "none";
    },
    hoverFilterValue() {
      return this.hoverColor != null
        ? hexToFilterString(this.hoverColor).filter
        : this.baseFilterValue;
    },
    styleObject() {
      let color =
        this.hoverState || this.hovered
          ? this.hoverFilterValue
          : this.baseFilterValue;
      return {
        filter: color,
      };
    },
  },
  methods: {
    updateHoverState(isHover) {
      this.hoverState = isHover;
    },
  },
};
</script>
  