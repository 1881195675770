import { createApp } from 'vue';
import App from './App.vue';
import { router } from './router';
import {store} from './store';
import http from '@/modules/http.js'
import onServerReject from '@/modules/on-server-reject.js'

import BootstrapVue3 from 'bootstrap-vue-3'

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'
import '@/styles/style.less'

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import {
  faHome,
  faUser,
  faUserPlus,
  faSignInAlt,
  faSignOutAlt,
  faCheck,
  faXmark
} from '@fortawesome/free-solid-svg-icons';

library.add(faHome, faUser, faUserPlus, faSignInAlt, faSignOutAlt, faCheck, faXmark);

http.interceptors.response.use(null, onServerReject)


const app = createApp(App);
app.use(require('vue-cookies'));
app.component('font-awesome-icon', FontAwesomeIcon);
app.use(BootstrapVue3)
app.use(router)
app.component("aTrackNavBar")
const user = JSON.parse(localStorage.getItem('user'));
if (user) {
  store.loggedIn = true;
  store.user = user;
}

app.mount('#app', true);


