<template>
  <h2>Чтобы открыть ссылку для регистрации, необходимо выйти из учётной записи.</h2>
</template>

<script>
export default {
  name: "RegistrationWhileLoggedIn"
}
</script>

<style scoped>

</style>