import { createRouter, createWebHistory } from 'vue-router';

import Home from './views/Home.vue';
import Login from './views/Login.vue';
import Register from './views/Register.vue';
import registrationWhileLoggedIn from "@/views/RegistrationWhileLoggedIn";


const routes = [
    {
      path: '/',
      name: 'homepage',
      redirect: () => {
        const user = JSON.parse(localStorage.getItem('user'));
        if (!user) { 
           return { path : "/login"};
        }
        if (user.isAdmin) {
          return { path: "/users" }
        }
        else {
          return { path: "/patients"};
        }
      },
      meta: {
        emptyLayout: true,
        disableNavBar : true
      }
    },
    {
      path: '/home',
      component: Home,
      meta: {
        emptyLayout: true
      }
    },
    {
      path: '/login',
      component: Login,
      meta: {
        emptyLayout: true
      },
      name: 'login'
    },
    {
      path: '/register/:token',
      component: Register,
      meta: {
        emptyLayout: true
      }
    },
    {
      path: '/profile',
      name: 'profile',
      // lazy-loaded
      component: () => import('./views/Profile.vue')
    },
    {
      path: '/users',
      name: 'users',
      // lazy-loaded
      component: () => import('./views/UsersList.vue')
    },
    {
      path: '/hospitals',
      name: 'hospitals',
      component: () => import('./views/LocationsList.vue')
    },
    {
        path: '/users/add',
        name: 'user-add',
        component: () => import('./views/UserAdd.vue'),
    },
    {
        path: '/devices',
        name: 'devices',
        component: () => import('./views/DevicesList.vue')
    },
    {
        path: '/patients',
        name: 'patients',
        component: () => import('./views/PatientsList.vue'),
        meta : {
            disableNavBar : true
        }
    },
    {
        path: '/patient/:id',
        name: 'patient',
        component: () => import('./views/PatientCard.vue'),
        meta : {
          disableNavBar : true
      }
  },
    {
        path: '/edit-patient/:id',
        name: 'edit-patient',
        component: () => import('./views/EditPatient.vue')
    },
    {
        path: '/checkup/:patientId/:day',
        name: 'checkup',
        component: () => import('./views/Checkup.vue'),
        meta : {
          disableNavBar : true
        }
    },
    {
      path: '/checkup-pdf/:patientId/:checkupId',
      name: 'checkup-pdf',
      component: () => import('./views/CheckupPdf.vue'),
      meta : {
        disableNavBar : true
      }
    },
    {
        path: '/register-logged-in',
        component: registrationWhileLoggedIn
    },
    {
      path:'/settings',
      name : 'settings',
      component: ()=> import('./views/Settings.vue')
    }

  ]

const router = createRouter({
  history: createWebHistory(),
  routes: routes
})

export { router };

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/home'];
  var authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  const isRegisterPage = to.matched && to.matched.some(element => element.path.toString() == "/register/:token");
  if (isRegisterPage && loggedIn) {
      next('/register-logged-in')
  }
  else {
      if (isRegisterPage) authRequired = false;
// trying to access a restricted page + not logged in
// redirect to login page
      if (authRequired && !loggedIn) {
          next('/login');
      } else {
          next();
      }
  }
});
