<template>
  <layout-empty v-if="isEmptyLayout"></layout-empty>
  <layout-main v-else></layout-main>
  <!-- <div id="app" style="background: #f5f5f5">
    <a-track-nav-bar v-show="currentUser" />
    <div class="container">
      <router-view />
    </div>
  </div> -->
</template>

<script>
import { store } from "@/store";
//import ATrackNavBar from "@/views/NavBar";
import LayoutEmpty from "@/views/LayoutEmpty.vue"
import LayoutMain from "@/views/LayoutMain.vue"

export default {
  components: {
    //ATrackNavBar,
    LayoutEmpty,
    LayoutMain,
  },
  data() {
    return {
      store,
    };
  },
  computed: {
    isEmptyLayout() {
      return this.$route.meta.emptyLayout === true
    },
    currentUser() {
      return this.store.user;
    },
  },
  mounted() {
    if (this.store.user) {
       var seconds_to_auto_logout = this.store.user.exp - (Date.now() / 1000);       
       setTimeout(() => {   this.logOut();  }, seconds_to_auto_logout * 1000);
       console.log (`auto logout in ${seconds_to_auto_logout} seconds`);
    }
  },
  methods: {
    logOut() {
      store.logout();
      this.$router.push("/login");
    },
  },
};
</script>
