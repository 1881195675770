<template>
  <div class="container">
    <main class="fixed-overlay">
      <div class="modal_container">
        <h5
          class="mb-4"
          style="display: flex; justify-content: center; font-weight: 700"
        >
          Вход в систему
        </h5>
        <form class="form-signin d-flex flex-column align-items-center" @submit.prevent="handleLogin">
          <div class="w-100">
            <b-form-input
              type="text"
              class="mb-2"
              id="floatingInput"
              placeholder="Логин (e-mail)"
              v-model="user.login"
              required
            />
          </div>
          <div class="w-100">
            <b-form-input
              type="password"
              class="mb-4"
              id="floatingPassword"
              placeholder="Пароль"
              v-model="user.password"
              required
            />
          </div>

          <div class="mb-3" style="display: flex; justify-content: center">
            <b-form-checkbox size="sm">Запомнить меня</b-form-checkbox>
          </div>
          <button
            class="w-100 btn btn-lg btn-primary"
            type="submit"
            :disabled="loading"
            style="max-width: 200px;"
          >
            <span
              v-show="loading"
              class="spinner-border spinner-border-sm"
            ></span>
            <span>Войти</span>
          </button>
          <div class="mt-3">
            <small>
            В информационной системе реализованы меры по&nbsp;обеспечению безопасности персональных данных.  Оператор системы должен соблюдать установленные правила обработки персональных данных.
          </small>
          </div>
        </form>
      </div>
      <div class="form-group">
        <div v-if="message" class="alert alert-danger" role="alert">
          {{ message }}
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import User from "../models/user";
import { store } from "@/store";

import("@/styles/components/login.less");

export default {
  name: "LoginPage",
  data() {
    return {
      store,
      user: new User("", ""),
      loading: false,
      message: "",
      justLoggedIn: false
    };
  },
  computed: {
    loggedIn() {
      return store.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      if (this.store.user.isAdmin) this.$router.push("/users");
      else this.$router.push("/patients");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      if (this.user.login && this.user.password) {
        this.store.login(this.user).then(
          () => {
            this.store.justLoggedIn = true;
            var seconds_to_auto_logout = this.store.user.exp - (Date.now() / 1000);
            setTimeout(() => {  
              store.logout(); 
              this.$router.push("/login");
             }, seconds_to_auto_logout * 1000);
            console.log (`auto logout in ${seconds_to_auto_logout} seconds`);
            if (this.store.user.isAdmin) this.$router.push("/users");
            else this.$router.push("/patients");
          },
          (error) => {
            this.loading = false;
            console.log(error)
            if (error.response && error.response.data == "This user is disabled")
              this.message = "Данная учетная запись заблокирована. Обратитесь к администратору";
            else {
              if (error.response && error.response.status == 403) 
                this.message = "Неправильное имя пользователя или пароль"
                else
                if (error.response && error.response.status == 429) 
                this.message = "Слишком много попыток входа. Повторите позже."
                else

            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString();
            }
          }
        );
      }
      //      });
    },
    logOut() {
      store.logout();
      this.$router.push("/login");
    },
  },
};
</script>

<style></style>
