import http from '../modules/http'
import authHeader from './auth-header';


class SettingsService {
    
    getSettings() {
        return http.get('configuration', { headers: authHeader() });
    }

    updateSettings(newSettings) {
        return http.patch('configuration', newSettings, { headers: authHeader() });
    }
}
export default new SettingsService();
