<template>
  <b-container>
  <b-form @submit.prevent="handleRegister" v-if="!successful">
    <b-form-group
        id="input-group-1"
        label="Имя пользователя:"
        label-for="input-1"
    >
      <b-form-input id="input-1" type="text" v-model="user.name"></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-2" label="Логин:" label-for="input-2">
      <b-form-input id="input-2" type="text" v-model="user.login" readonly></b-form-input>
    </b-form-group>
    <b-form-group id="input-group-3" label="Пароль:" label-for="input-3">
      <b-form-input id="input-3" type="password" v-model="user.password"></b-form-input>
    </b-form-group>
    <b-button type="submit" variant="primary">Отправить</b-button>
  </b-form>
  <div v-if="successful">
    Регистрация закончена
    <b-button variant="primary" @click="jumpToLoginPage">Войти</b-button>
  </div>
  </b-container>
</template>

<script>
import User from '../models/user';
import http from '../modules/http'

export default {
  name: 'RegisterPage',
  data() {
    return {
      user: new User('', '', ''),
      submitted: false,
      successful: false,
      message: '',
      loading : false
    };
  },
  mounted() {
      this.loading = true
      http.get(`/user-by-token/${this.$route.params["token"] }`)
          .then(response => response.data)
          .then(data => {
            this.user.login = data.login
            this.user.name = data.name
          })
          .catch(error => {
            alert(error.response.message)
          })
          .finally(() => (this.loading = false))
  },
  methods: {
    handleRegister() {
      this.message = '';
      this.submitted = true;
      http.put (`/register-by-token/${this.$route.params["token"] }`, this.user)
          .then(response => response.data)
          .then(() => {
             this.successful = true

             // регистрация выполнена, перекинуть на /login ?
          })
          .catch(error => {
            alert(error.response.message)
          })
          .finally(() => (this.submitted = false))
    },
    jumpToLoginPage() {
      this.$router.push("/login")
    }

  }
};
</script>

<style scoped>

</style>