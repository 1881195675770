import http from '../modules/http'
import jwt_decode from "jwt-decode";
class AuthService {
  login(user) {
    return http
      .post('/login', {
        login: user.login,
        password: user.password
      })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('jwt_token',response.data.accessToken)
          localStorage.setItem('lastLogin', response.data.lastLogin)
          const decodedToken = jwt_decode(response.data.accessToken)
          localStorage.setItem('user', JSON.stringify(decodedToken));
          return decodedToken;
        }
        else
          return Promise.reject("Invalid response to login");
      });
  }
  logout() {
    localStorage.removeItem('user');
  }
  register(user) {
    return http.post('signup', {
      username: user.username,
      email: user.email,
      password: user.password
    });
  }
}
export default new AuthService();