import http from '../modules/http'
import authHeader from './auth-header';


class UserService {
  getPublicContent() {
    return http.get('all');
  }

  getUserBoard() {
    return http.get('ololo', { headers: authHeader() });
  }

  getModeratorBoard() {
    return http.get('mod', { headers: authHeader() });
  }

  getAdminBoard(skip, limit, filterString) {
    return http.get(`users?$skip=${skip}&$limit=${limit}&${filterString}`, { headers: authHeader() });
  }

  resetPassword(userId) {
     return http.patch(`users/${userId}`, { status : 5}, { headers: authHeader()});
  }

  removeUser(userId) {
    return http.delete(`users/${userId}`, { headers: authHeader() });
  }
}

export default new UserService();