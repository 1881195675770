<template>
  <div class="wrapper">
    <div v-if="enableNavBar" class="wrapper-nav">
      <navigation></navigation>
    </div>

    <div class="wrapper-header">
      <header-body v-if="enableNavBar"></header-body>
      <header-body v-else class="header-no-navbar"></header-body>
    </div>

    <div class="wrapper-content">

      <div class="main-content container">
        <router-view></router-view>
      </div>

      <footer-body></footer-body>
    </div>
  </div>
</template>

<script>
import FooterBody from '@/views/FooterBody.vue'
import HeaderBody from '@/views/HeaderBody.vue'
import Navigation from '@/views/Navigation.vue'
import '@/styles/pages/main-layout.less'


export default {
  name: 'LayoutMain',
  components: {
    Navigation,
    HeaderBody,
    FooterBody
  },
  computed: {
    enableNavBar() {
      if (this.$route.path == '/' && !this.$route.name) {
         return false;
      }
      return !this.$route.meta.disableNavBar
    }
  }
}
</script>
